// slice to handle if snackbar should be shown or not
import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createSlice } from '@reduxjs/toolkit'
import SnackbarSeverity from '@/types/SnackbarSeverity'

export interface SnackbarState {
  show: boolean;
  message: string;
  severity: SnackbarSeverity;
}

const initialState: SnackbarState = {
  show: false,
  message: '',
  severity: SnackbarSeverity.Success,
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    hideSnackbar: (state) => {
      state.show = false
    },
    showSnackbar: (state, action) => {
      state.show = true
      state.message = action.payload.message
      state.severity = action.payload.severity
    }
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.snackbar,
      }
    })
  }
})

export const selectShow = (state: RootState) => state.snackbar.show
export const selectMessage = (state: RootState) => state.snackbar.message
export const selectSeverity = (state: RootState) => state.snackbar.severity

export const { hideSnackbar, showSnackbar } = snackbarSlice.actions