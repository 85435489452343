import { ContactFormModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const contactApi = createApi({
  reducerPath: 'contact',
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    postContact: builder.mutation<void, ContactFormModel>({
      query: (contactForm) => ({
        url: 'contact',
        method: 'POST',
        body: contactForm,
      }),
    }),
  }),
})

export const { usePostContactMutation } = contactApi