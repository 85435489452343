import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const sessionApi = createApi({
  reducerPath: 'session',
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    getSession: builder.query<void, void>({
      query: () => `Session`,
    })
  })
})

export const { useGetSessionQuery } = sessionApi
