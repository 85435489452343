import { getBaseQuery } from "@/utils/apiHelper"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { HYDRATE } from "next-redux-wrapper"

export const baseApi = createApi({
  baseQuery: getBaseQuery,
  tagTypes: [
    'Login',
    'Customer',
    'Cart',
    'Search',
  ],
  endpoints: () => ({}),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
})
