import { ReceiptModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const receiptApi = createApi({
  reducerPath: 'receipt',
  baseQuery: getBaseQuery,
  tagTypes: ['Receipt'],
  endpoints: (builder) => ({
    getReceipts: builder.query<ReceiptModel[], void>({
      query: () => `Receipts`,
      providesTags: ['Receipt']
    })
  }),
})

export const {
  useGetReceiptsQuery,
} = receiptApi