import { ArticleCriteriaModel, ArticleModel } from '@/types/api'
import { baseApi } from './baseApi'

export const articleApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getArticle: builder.query<ArticleModel, ArticleCriteriaModel>({
      query: (criteria) => ({
        url: 'article',
        method: 'GET',
        params: criteria,
      }),
      providesTags: ['Search'],
    }),
  }),
})

export const { useGetArticleQuery, useLazyGetArticleQuery, util: { getRunningQueriesThunk } } = articleApi
export const { getArticle } = articleApi.endpoints