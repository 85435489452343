import { ColorModel } from "@/types/api" 
import { getBaseQuery } from "@/utils/apiHelper"
import { createApi } from "@reduxjs/toolkit/dist/query/react"

export const colorsApi = createApi({
  reducerPath: 'colors',
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    getColors: builder.query<ColorModel[], void>({
      query: () => `Colors`,
    }),
  }),
})

export const { useGetColorsQuery } = colorsApi