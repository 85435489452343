import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createAction, createSlice } from '@reduxjs/toolkit'

// Type for our state
export interface UserState {
  authState: boolean;
}

// Initial state
const initialState: UserState = {
  authState: false,
}

// Actual Slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {

    // Action to set the authentication status
    setAuthState(state, action) {
      state.authState = action.payload
    },
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.user,
      }
    })
  },
})

export const { setAuthState } = userSlice.actions

export const selectAuthState = (state: RootState) => state.user.authState

export default userSlice.reducer