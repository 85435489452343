import { CreateOrderModel, OrderConfirmationInfoModel, OrderCreatedModel, OrderInfoModel, OrderModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const orderApi = createApi({
  reducerPath: 'order',
  baseQuery: getBaseQuery,
  tagTypes: ['Order', 'Cart'],
  endpoints: (builder) => ({
    getOrder: builder.query<OrderInfoModel, number>({
      query: (id) => `Order/${id}`,
    }),
    getOrders: builder.query<OrderModel[], void>({
      query: () => `Order`,
      providesTags: ['Order']
    }),
    getPaymentStatus: builder.query<boolean, number>({
      query: (id) => `Order/Status/${id}`,
    }),
    getConfirmationInfo: builder.query<OrderConfirmationInfoModel, number>({
      query: (id) => `Order/Confirmation/${id}`,
    }),
    createOrder: builder.mutation<OrderCreatedModel, CreateOrderModel>({
      query: (payload) => ({
        url: `Order`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Cart'],
    }),
  }),
})

export const {
  useGetOrderQuery,
  useGetOrdersQuery,
  useGetPaymentStatusQuery,
  useCreateOrderMutation,
  useLazyGetConfirmationInfoQuery,
  useLazyGetOrderQuery
} = orderApi
