import { getBaseQuery } from "@/utils/apiHelper"
import { createApi } from "@reduxjs/toolkit/query/react"

export const resetPasswordApi = createApi({
  reducerPath: "resetPassword",
  baseQuery: getBaseQuery,
  tagTypes: ["ResetPassword"],
  endpoints: (builder) => ({
    resetPassword: builder.mutation<void, { email: string }>({
      query: (payload) => ({
        url: "resetPassword",
        method: "POST",
        body: payload,
      }),
    }),
  }),
})

export const { useResetPasswordMutation } = resetPasswordApi