import { ArticleModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

export const highlightsApi = createApi({
  reducerPath: 'highlights',
  baseQuery: getBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    getHighlights: builder.query<ArticleModel[], void>({
      query: () => `Highlights`,
    }),
  }),
})

export const { useGetHighlightsQuery, util: { getRunningQueriesThunk } } = highlightsApi
export const { getHighlights } = highlightsApi.endpoints