import { InvoiceModel, InvoiceRowModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const invoiceApi = createApi({
  reducerPath: 'invoice',
  baseQuery: getBaseQuery,
  tagTypes: ['Invoice'],
  endpoints: (builder) => ({
    getInvoices: builder.query<InvoiceModel[], void>({
      query: () => `Invoice`,
      providesTags: ['Invoice']
    }),
    getInvoiceRows: builder.query<InvoiceRowModel[], number>({
      query: (id) => `InvoiceRow/${id}`,
    }),
  }),
})

export const {
  useGetInvoicesQuery,
  useGetInvoiceRowsQuery,
} = invoiceApi