import { DeliveryAddressCreateModel, DeliveryAddressDetailsModel, DeliveryAddressEditModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const deliveryAddressesApi = createApi({
  reducerPath: 'deliveryAddresses',
  baseQuery: getBaseQuery,
  tagTypes: ['DeliveryAddress'],
  endpoints: (builder) => ({
    getDeliveryAddresses: builder.query<DeliveryAddressDetailsModel[], void>({
      query: () => 'deliveryAddresses',
      providesTags: ['DeliveryAddress'],
    }),
    addDeliveryAddress: builder.mutation<void, DeliveryAddressCreateModel>({
      query: (payload) => ({
        url: 'deliveryAddresses',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['DeliveryAddress'],
    }),
    deleteDeliveryAddress: builder.mutation<void, number>({
      query: (id) => ({
        url: `deliveryAddresses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeliveryAddress'],
    }),
    setDefaultAddress: builder.mutation<void, DeliveryAddressEditModel>({
      query: (payload) => ({
        url: `deliveryAddresses/`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['DeliveryAddress'],
    }),
  }),
})

export const {
  useGetDeliveryAddressesQuery,
  useAddDeliveryAddressMutation,
  useDeleteDeliveryAddressMutation,
  useSetDefaultAddressMutation,
} = deliveryAddressesApi