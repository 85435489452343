import { CountryModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const countriesApi = createApi({
  reducerPath: 'countries',
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    getCountries: builder.query<CountryModel[], void>({
      query: () => `countries`,
    }),
  }),
})


export const { useGetCountriesQuery } = countriesApi