import { TyreWidthAspectRatiosModel, WidthAspectRatioModel } from '@/types/api'
import { createUrlQuery, getBaseQuery } from '@/utils/apiHelper'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

export const tyreSizesApi = createApi({
  reducerPath: 'tyreSizes',
  baseQuery: getBaseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (builder) => ({
    getTyreSizes: builder.query<TyreWidthAspectRatiosModel[], void>({
      query: () => `TyreSizes`,
    }),
    getDiameters: builder.query<number[], WidthAspectRatioModel>({
      query: (payload) => `TyreSizes/Diameter?${createUrlQuery(payload)}`,
    }),
  }),
})

export const { useGetTyreSizesQuery, useLazyGetDiametersQuery, util: { getRunningQueriesThunk } } = tyreSizesApi
export const { getTyreSizes } = tyreSizesApi.endpoints