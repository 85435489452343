import { useGetSessionQuery } from '@/api/session'
import React from 'react'

type Props = {}

const SessionManager = (props: Props) => {
  const { data } = useGetSessionQuery()

  return null
}

export default SessionManager