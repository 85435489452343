import { CurrencyModel } from '@/types/api'
import { getBaseQuery } from '@/utils/apiHelper'
import { createApi } from '@reduxjs/toolkit/query/react'

export const currenciesApi = createApi({
  reducerPath: 'currencies',
  baseQuery: getBaseQuery,
  endpoints: (builder) => ({
    getCurrencies: builder.query<CurrencyModel[], void>({
      query: () => `Currency`,
    }),
  }),
})

export const { useGetCurrenciesQuery } = currenciesApi