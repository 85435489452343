import { useAppDispatch } from '@/utils/hooks/useAppDispatch'
import { useAppSelector } from '@/utils/hooks/useAppSelector'
import React from 'react'
import { hideSnackbar, selectMessage, selectSeverity, selectShow } from '../snackbarSlice'
import { Alert, Snackbar } from '@mui/material'

type Props = {}

const SnackbarManager = (props: Props) => {
  const dispatch = useAppDispatch()
  const show = useAppSelector(selectShow)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)

  const onClose = () => {
    dispatch(hideSnackbar())
  }

  return (
    <div>
      <Snackbar
        open={show}
        onClose={onClose}
        autoHideDuration={4000}>
        <Alert severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default SnackbarManager