import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createSlice } from '@reduxjs/toolkit'

export interface AppState {
  currency: string;
  language: string;
  country: string;
  includeVat: boolean;
  countryChecked: boolean;
}

const initialState: AppState = {
  currency: 'SEK',
  country: 'SE',
  language: 'en',
  includeVat: true,
  countryChecked: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCountry: (state, action) => {
      state.country = action.payload
    },
    setCurrency: (state, action) => {
      state.currency = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setIncludeVat: (state, action) => {
      state.includeVat = action.payload
    },
    setCountryChecked: (state, action) => {
      state.countryChecked = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.app,
      }
    })
  },
})

export const {
  setCurrency,
  setCountry,
  setLanguage,
  setIncludeVat,
  setCountryChecked,
} = appSlice.actions

export const selectCurrency = (state: RootState) => state.app.currency
export const selectLanguage = (state: RootState) => state.app.language
export const selectIncludeVat = (state: RootState) => state.app.includeVat
export const selectCountry = (state: RootState) => state.app.country
export const selectCountryChecked = (state: RootState) => state.app.countryChecked

export default appSlice.reducer
