import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query"

/**
 * Gets the public API URL based on the environment
 * @returns 
 */
export const apiURL = (): string => {
  return `${process.env.NEXT_PUBLIC_HOST}/api/`
}

/**
 * Creates a query string from an object
 * @param criteria - object to create query string from
 * @returns 
 */
export const createUrlQuery = (criteria: any): string => {
  let query = ''
  for (const key in criteria) {
    if (criteria[key] !== undefined) {
      query += `${key}=${criteria[key]}&`
    }
  }
  return query
}

/**
 * Gets the base query for the API
 */
export const getBaseQuery = fetchBaseQuery({
  baseUrl: apiURL(),
  credentials: "include",
  prepareHeaders: (headers, { extra }: any) => {
    headers.set("cookie", extra.cookies)
    return headers
  },
})