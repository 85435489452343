import { RootState } from '@/store/store'
import { HYDRATE } from '@/store/actions'
import { createSlice } from '@reduxjs/toolkit'

// Type for our state
export interface VehicleState {
  brand?: number,
  model?: number,
  year?: number,
  engine?: number,
  licensePlate?: string,
  licenseCountryCode: string,
  licensePlateValid: boolean,
}

// Initial state
const initialState: VehicleState = {
  licenseCountryCode: 'SE',
  licensePlateValid: false,
}

// Actual Slice
export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    setLicensePlateCountry: (state, action) => {
      state.licenseCountryCode = action.payload
    },
    setLicensePlate: (state, action) => {
      state.licensePlate = action.payload
    },
    setLicensePlateValid: (state, action) => {
      state.licensePlateValid = action.payload
    },
    setBrand: (state, action) => {
      state.brand = action.payload
    },
    setModel: (state, action) => {
      state.model = action.payload
    },
    setYear: (state, action) => {
      state.year = action.payload
    },
    setEngine: (state, action) => {
      state.engine = action.payload
    },
    resetVehicle: (state) => {
      return {
        ...initialState,
        licenseCountryCode: state.licenseCountryCode
      }
    }
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (state, action) => {
      return {
        ...state,
        ...action.payload.vehicle,
      }
    })
  },
})

export const {
  setLicensePlate,
  setLicensePlateCountry,
  setLicensePlateValid,
  setBrand,
  setModel,
  setYear,
  setEngine,
  resetVehicle
} = vehicleSlice.actions

export const selectBrand = (state: RootState) => state.vehicle.brand
export const selectModel = (state: RootState) => state.vehicle.model
export const selectYear = (state: RootState) => state.vehicle.year
export const selectEngine = (state: RootState) => state.vehicle.engine
export const selectLicensePlate = (state: RootState) => state.vehicle.licensePlate
export const selectLicensePlateCountryCode = (state: RootState) => state.vehicle.licenseCountryCode
export const selectLicensePlateValid = (state: RootState) => state.vehicle.licensePlateValid

export default vehicleSlice.reducer